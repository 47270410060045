.payment-giftcard {
  &__title {
    overflow: hidden;
    position: relative;
    width: 100%;
    &::after {
      @include svg-icon-inline-mask('caret--down');
    }
    &.open {
      &::after {
        @include svg-icon-inline-mask('caret--up');
      }
    }
    &::after,
    &.open::after {
      content: '';
      background-color: $color-off-black;
      width: 16px;
      height: 16px;
      display: inline-block;
      position: absolute;
      right: 0;
      text-align: right;
      top: 5px;
    }
  }
  &__check-balance {
    margin-bottom: 20px;
    .giftcard-check-balance {
      border: 1px solid $color-off-black;
      display: block;
      height: 36px;
      line-height: 2.5;
      padding: 0;
      text-align: center;
      text-decoration: none;
      width: 100%;
      @include breakpoint($medium-up) {
        width: 40%;
      }
    }
  }
  .form-submit {
    width: 100%;
    @include breakpoint($medium-up) {
      width: 40%;
    }
  }
}
