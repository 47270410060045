.optanon-toggle-display,
.optanon-allow-all {
  &:hover {
    background: none;
  }
}

.cookie-settings-ot {
  margin-bottom: 20px;
  height: 34px;
  @include breakpoint($landscape-up) {
    margin-bottom: 0;
  }
}

.cs-page {
  @include breakpoint($landscape-up) {
    margin-bottom: 20px;
  }
}

.optanon-alert-box-wrapper {
  .optanon-alert-box-bg {
    .optanon-alert-box-body {
      @include breakpoint($medium-up) {
        margin: 0 40px;
      }
    }
    .optanon-alert-box-button-container {
      @include breakpoint($medium-up) {
        position: relative;
        margin: 5px;
      }
    }
  }
  .optanon-alert-box-bottom-top {
    .optanon-alert-box-corner-close {
      @include breakpoint($medium-up) {
        top: auto;
        bottom: 22px;
      }
    }
  }
}
