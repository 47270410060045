.site-header {
  &__wrapper {
    .block-template-country-picker-eu-v1 {
      padding-top: 15px;
      position: absolute;
      width: 32%;
      top: 63px;
      right: 74px;
      z-index: 999;
    }
  }
  .user-loyalty {
    a.user-loyalty__program {
      display: none;
    }
  }
  &__content {
    .pure-privilege-container {
      display: none;
    }
  }
  &__nav-right {
    .site-header__main & {
      @if $cr20 == true {
        display: block;
        @include breakpoint($landscape-up) {
          margin-bottom: 14px;
        }
      }
    }
    @if $cr20 == true {
      .site-header {
        &__menu-language-country {
          float: $ldirection;
          .gnav-country-language-chooser {
            margin-top: 5px;
          }
        }
        &__menu-item {
          .site-header__menu .site-header__menu-list--desktop & {
            float: $rdirection;
            margin-#{$rdirection}: auto;
          }
        }
      }
    }
  }
}
/* Country Picker_eu_v1 */
#countryPicker li.country {
  text-align: left;
  padding: 0.5em 0.3em;
  width: 48%;
  float: left;
}
/* country_picker_formatter_v1 */
#countryPicker {
  width: 240px;
  z-index: 999;
}

#countryPickerLinkWrap {
  float: right;
}

.language-list {
  float: right;
  li {
    float: left;
  }
}

.current-lang {
  float: left;
}

ul#countryList {
  padding: 0;
  list-style: none;
}

ul.country-list-offsite {
  padding: 0;
  list-style: none;
}

.language-list {
  li {
    padding: 0.5em;
    cursor: pointer;
  }
}

#countryPickerLink {
  padding: 0.5em;
  cursor: pointer;
}

.language-list {
  li {
    line-height: 1.5em;
  }
}

.language-list {
  padding-left: 0;
  margin: 0 1em 0 0;
  list-style: none;
}

#countryPickerLinkWrap:hover {
  #countryPickerLink {
    z-index: 1000;
    border-top: 1px solid $color-off-black;
    border-right: 1px solid $color-off-black;
    border-left: 1px solid $color-off-black;
    border-bottom: 1px solid $white;
  }
}

.country-link {
  border-bottom-color: transparent;
  text-decoration: none;
  img {
    vertical-align: middle;
  }
}

.country-link:hover {
  border-bottom-color: transparent;
  text-decoration: none;
}

#countryPicker {
  ul.country-list {
    margin: 0;
    padding: 0;
  }
  ul.country-list-offsite {
    margin: 0;
    padding: 0;
  }
}

#countryPickerLink {
  border: 1px solid #cccccc;
  padding: 0.25em 0.5em;
  width: 32px;
  position: relative;
  margin-bottom: -1px;
  color: $white;
}

#countryPickerLink img {
  display: block;
}

.gnav-item-v1 {
  #countryPicker.gnav-item-content {
    position: absolute;
    background-color: $white;
    text-align: center;
    min-width: 240px;
    border: 1px solid $color-off-black;
  }
}

.block-template-country-picker-eu-v1 {
  .current-lang {
    font-weight: bold;
  }
  .language-list {
    li {
      margin-left: 1em;
      padding-top: 0;
    }
  }
}
/* locale_interstitial style */
#localeOverlay {
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  display: none;
  position: fixed;
}

#localeSwitchOverlay {
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  display: none;
  position: fixed;
}

#localeSwitchWarning {
  text-align: center;
  position: relative;
}

#localeSwitchWarning {
  .column {
    float: right !important;
    width: 50%;
  }
}

#localeInterstitial {
  .column {
    padding: 1em;
    float: left;
  }
}

#localeSwitchOverlay {
  .column {
    padding: 1em;
    float: left;
  }
}

#localeInterstitial {
  background: $white;
  margin: auto;
  line-height: 2em;
  padding: 2px;
  border: 3px solid $color-off-black;
  text-align: center;
}

#localeInterstitial {
  .initial-hide {
    display: none;
  }
}

#localeInterstitial {
  ul {
    font-size: 0; /* Fix for country options having $color-whitespace between them */
    width: 100%;
  }
}

#localeSwitchWarning {
  .btn-secondary {
    background: $white;
    color: $color-off-black;
  }
}

#chooseLocaleButton {
  text-transform: uppercase;
  color: $white;
  padding-left: 2em;
  padding-right: 2em;
  background: $color-off-black;
}

#localeOverlay {
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

#localeInterstitial {
  li {
    display: inline-block;
    float: none;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
  }
  ul.country-list {
    padding: 0;
    list-style-type: none;
  }
  ul.country-list-offsite {
    padding: 0;
    list-style-type: none;
  }
  ul.language-list {
    padding: 0;
    list-style-type: none;
  }
  .language-list {
    li {
      padding: 0;
    }
  }
}

.country-list-offsite {
  li {
    width: 50%;
  }
}

.language-list {
  li.selected {
    font-weight: bold;
  }
}

.current-country {
  font-weight: bold;
}

.selected-lang {
  font-weight: bold;
}

.country-link.selected {
  font-weight: bold;
}

#localeInterstitial {
  .country-link {
    display: inline-block;
    line-height: 2.5em;
  }
}

#localeInterstitial {
  .label {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
  }
}

#localeInterstitial {
  .inner-border {
    border: 1px solid $color-off-black;
    padding: 0.5em;
  }
}

.choose-country {
  font-size: 12px;
  margin: 0.5em 0;
}
/* locale Switch Warning */
#localeSwitchWarning {
  background: $white;
  margin: auto;
  font-size: 14px;
  line-height: 2em;
  width: 30%;
  padding: 2em;
}
/* End */

/* Site Specific code */
#mobileCountryLink {
  display: none;
}

#localeSwitchWarning {
  width: 50% !important;
  max-width: 600px;
  top: 30%;
}

#localeSwitchWarning {
  .button {
    width: 80%;
    margin-top: 1em;
  }
}

#changeLocaleButton {
  float: left;
  margin-left: 0.5em;
}

#cancelChangeLocaleButton {
  float: right;
  margin-right: 0.5em;
}

#localeInterstitial {
  .column,
  #localeSwitchWarning {
    .column {
      width: 50%;
    }
  }
}

#localeInterstitial {
  width: 80%;
  max-width: 800px;
  position: relative;
  top: 12%;
}

#chooseLocaleButton {
  width: auto;
}

#localeInterstitial {
  input[type='radio'] {
    opacity: 1;
    margin: 0;
    position: relative;
    left: inherit;
  }
}

#localeInterstitial {
  .country-link {
    font-size: 12px;
  }
}

#localeInterstitial {
  li {
    width: 33%;
  }
}

.gnav-item-v1 {
  #countryPicker {
    display: none;
  }
}

.gnav-item-v1:hover {
  #countryPicker {
    display: block;
  }
}

.block-template-country-picker-eu-v1 {
  float: left;
  margin-top: 0px;
  #countryPickerWrap {
    padding-right: 5px;
    .language-list {
      font-size: 14px;
      margin-right: 5px;
      li {
        margin-left: 1px;
        padding: 5px;
        line-height: 8px;
        color: $white;
      }
    }
    #countryPicker {
      .country-list {
        font-size: 14px;
      }
    }
  }
}

.welcome15-overlay {
  .welcome-15 {
    height: 320px;
    .site-email-signup__success--welcome-15 {
      form.welcome-15_site-email-signup {
        .email_input {
          input.form-text {
            float: left;
            margin: 0 10px 10px 0;
          }
          input.form-submit {
            width: 100px;
            float: left;
            margin: 0 0 10px 0;
          }
        }
      }
      .site-email-signup__terms-details {
        a {
          color: #fff;
        }
      }
    }
    &.success {
      padding: 2.5em 1em 0 1.5em;
      .site-email-signup__success-header {
        font-size: 35px;
        padding-bottom: 15px;
        width: 95%;
      }
      .site-email-signup__success-offer {
        width: 95%;
      }
      .site-email-signup__success-reminder {
        margin-top: 1em;
        width: 95%;
      }
    }
  }
}

.product-full {
  .product-sku-price {
    text-align: left;
  }
  .product-sku-price__value.unit_price {
    float: none;
  }
  .product-sku-unit-price__value {
    float: left;
    width: 65%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .site-header {
    &__wrapper {
      .block-template-country-picker-eu-v1 {
        width: 38%;
        top: 40px;
      }
    }
  }
}

.product-quickshop {
  .product-quickshop__add-to-bag {
    a.product-add-to-waitlist {
      background: $color-off-black;
      color: $white;
      border-color: $white;
      &:hover {
        background: $white;
        color: $color-off-black;
        border-color: $color-off-black;
      }
    }
  }
  .product-sku-unit-price__value {
    width: 100%;
  }
}

.qs-overlay-cbox {
  #cboxLoadedContent {
    padding: 70px 20px 20px 20px;
    .product-full__quote {
      top: 120px;
    }
  }
}

.waitlist-iframe-wrapper {
  width: 100%;
}

.tiny-waitlist-overlay {
  h2.waitlist_header {
    margin: 0px 0px 10px 0px;
  }
  .email_comp {
    .email_input,
    .float-left {
      float: left;
      display: inline-block;
      margin-right: 10px;
    }
  }
}

.offer-block-formatter {
  .site-email-signup {
    &__form {
      .site-email-signup__field:last-child {
        display: block;
      }
    }
    &__terms {
      .site-email-signup__terms-conditions__more {
        margin-bottom: 0;
      }
    }
  }
}

.content-block-split__cta-wrap {
  margin-bottom: 30px;
}

.spp-content {
  .product-full {
    .p-w-r {
      .pr-review-snippet-container {
        float: none;
      }
    }
    .product-shade-picker {
      width: 100%;
    }
  }
}
