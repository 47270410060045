$header-gray: #ccc;
$color-gray-dark: #333333;

@mixin search-box-toggle {
  width: 32%;
  font-size: inherit;
  margin-right: 2px;
  float: left;
  clear: none;
}

#map-overlay-background {
  background-color: $white;
  opacity: 1;
}

.local-collection-map {
  height: 100%;
  iframe {
    border: 0;
    width: 100%;
    height: 97%;
  }
}

.set-collection-point,
.show-collection-point {
  border-top: 1px dotted $header-gray;
  padding-top: 10px;
  margin-top: 10px;
  float: left;
  width: 100%;
}

.set-collection-point {
  h3 {
    margin: 0 0 5px 0;
  }
  .local-collection-button {
    display: inline-block;
    padding: 5px 0 0 6px;
    height: 15px;
    width: 100%;
  }
}

.show-collection-point {
  h4 {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: -1px;
    a.local-collection-link {
      margin-left: 15px;
      border-bottom: 1px solid #392720;
      text-decoration: none;
      display: inline-block;
      font-size: 14px;
    }
  }
}

.local-collection-button {
  background-color: grey;
  cursor: pointer;
  border: 1px solid $color-off-black;
  background: $color-off-black;
  color: $white;
  padding: 9px 19px 8px 19px;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  min-width: 116px;
  max-width: 100%;
  font-size: 15px;
  line-height: 16px;
  &:hover {
    background: $white;
    color: $color-off-black;
    cursor: pointer;
    text-decoration: none;
  }
}

.map-container {
  .map {
    height: 300px;
  }
  .address-map-search-panel {
    font-size: 1.4em;
    .search-box {
      overflow: hidden;
      label {
        display: block;
        font-size: inherit;
        margin: 0 0 1em;
        font-weight: bold;
      }
    }
    .search-box-field {
      width: 100%;
      font-size: inherit;
      margin: 0;
      height: 30px;
      -webkit-appearance: textfield;
    }
    .search-box-toggle {
      @include search-box-toggle;
    }
    .search-box-submit {
      @include search-box-toggle;
      margin-left: 0;
    }
    .search-box-cancel {
      @include search-box-toggle;
    }
    .status-bar-wrapper {
      width: 80%;
      margin-left: 10%;
      .status-bar {
        .message {
          padding: 0.5em;
          border: 1px solid #aaa;
          box-shadow: 0 1px 4px $color-gray-dark;
          &:first-child {
            border-radius: 5px 5px 0 0;
          }
          &:last-child {
            border-radius: 0 0 5px 5px;
          }
          .close {
            float: right;
            a {
              text-decoration: none;
              color: inherit;
            }
          }
        }
        .message.error {
          background: #fcc;
          border-color: #a66;
        }
        .message.warning {
          background: #ffc;
          border-color: #aa6;
        }
        .message.success {
          background: #cfc;
          border-color: #6a6;
        }
        .message.information {
          background: #bdf;
          border-color: #68a;
        }
      }
    }
  }
  .search-box-geolocate {
    @include search-box-toggle;
  }
  .address-map-directions-panel,
  .address-map-location-panel {
    display: none;
    .locations {
      height: 100%;
      max-height: 100%;
      overflow-y: scroll;
      background-color: $white;
      cursor: pointer;
      font-family: roboto, arial, verdana;
    }
    .location {
      overflow: hidden;
      padding: 5px;
      margin: 0 0 1em;
      border: 1px solid #ededed;
      color: $black;
      border-radius: 4px;
      -moz-border-radius: 4px;
      -o-border-radius: 4px;
      -ms-border-radius: 4px;
      -webkit-border-radius: 4px;
      background: #f7f7f7;
      -webkit-transition: padding 0.5s, background 0.5s;
      transition: padding 0.5s, background 0.5s;
      font-size: 14px;
      p {
        margin: 0 0 0.5em;
      }
      .image {
        float: left;
        width: 100px;
        margin: 0 1em 0 0;
        img {
          width: 90%;
        }
      }
      .distance {
        float: right;
        color: #999;
        .uom {
          text-transform: lowercase;
        }
      }
      .location_description {
        font-style: italic;
      }
      .title {
        h2 {
          font-weight: bold;
          font-size: 14px;
          border: 0;
          padding: 0.5em 0;
          color: $black;
        }
      }
      div.button {
        clear: both;
        margin-top: 1em;
        background: transparent;
        line-height: 18px;
        padding: 0px;
        border: none;
      }
    }
  }
  .address-map-location-panel {
    .location {
      min-width: 200px;
      &.selected,
      &:hover {
        background: $color-light-gray;
        border: 1px solid $color-off-black;
      }
    }
    .locations {
      .title {
        span {
          font-weight: bold;
          line-height: 17px;
        }
      }
      .opening_hours {
        p {
          font-size: 11px;
          font-weight: bold;
          font-weight: bold;
        }
      }
      .address {
        p {
          font-weight: bold;
        }
      }
      .location_description {
        p {
          font-weight: bold;
        }
      }
    }
  }
  .button {
    a {
      display: inline-block;
      font-family: inherit;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      padding: 3px 6px;
      background: $color-off-black;
      color: $white;
      border: none;
      white-space: nowrap;
      cursor: pointer;
      text-shadow: none;
      &.btn {
        letter-spacing: 0;
        line-height: 20px;
        border-radius: 4px;
        border: 1px solid $color-off-black;
        &:hover {
          cursor: pointer;
          background: $white;
          color: $color-off-black;
          text-decoration: none;
        }
      }
    }
  }
  .map-info-window {
    background: $white;
    color: $black;
    padding: 5px;
    .location {
      width: 268px;
      padding: 5px;
      .title {
        padding-bottom: 5px;
        span {
          color: $black;
          margin: 0;
          font-size: 14px;
          font-weight: bold;
        }
      }
      div {
        margin-bottom: 3px;
      }
      .image {
        img {
          max-width: 100%;
        }
      }
      .button {
        border: none;
        padding: 0px;
        a {
          float: left;
          padding: 3px 6px;
          border: 1px solid $color-off-black;
          &.select-access-point {
            margin: 0 15px 0 0;
          }
          &:hover {
            background: $white;
            border: 1px solid $color-off-black;
          }
        }
        &:hover {
          background: transparent;
        }
      }
    }
  }
  .directions {
    background: $white;
    color: $black;
    overflow-y: scroll;
    max-height: 100%;
    clear: left;
    font-size: 12px;
    padding: 10px;
    .button {
      width: 100%;
      border: none;
      text-decoration: none;
      a.btn {
        font-size: 15px;
      }
      &:hover {
        background: none;
      }
    }
  }
}

.adp-placemark {
  background-color: $white;
  td {
    padding: 2px 0px 2px 15px;
    color: $black;
  }
}

.adp-substep {
  color: $black;
}

.adp-summary {
  color: $black;
}

.adp-text {
  color: $black;
}

.select-access-point_dir {
  display: inline-block;
  font-family: inherit;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 3px 6px;
  background: $color-off-black;
  color: #fff;
  border: none;
  $white-space: nowrap;
  cursor: pointer;
  text-shadow: none;
}

div#map-foreground-node {
  padding: 0;
  &.overlay-container.local-collection.click_and_collect_map {
    @include breakpoint($small-down) {
      height: 100% !important;
    }
  }
}

.click_and_collect_map {
  width: 100%;
  position: fixed;
  top: 0;
  .close-container {
    display: none;
  }
  &.overlay-container {
    z-index: 9999;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    overflow-y: auto;
    overflow-x: auto;
  }
  .overlay-content {
    height: 100%;
    width: 100%;
    position: relative;
    .map-container {
      height: 100%;
      position: relative;
      overflow: hidden;
      .address-map-search-panel {
        * {
          box-sizing: border-box;
        }
        position: absolute;
        top: 0;
        z-index: 1;
        margin-left: 30px;
        margin-top: 1.5em;
        border-top: 0;
        .status-bar-wrapper {
          position: fixed;
          bottom: 20px;
          left: 50%;
          width: 30em;
          margin-left: -15em;
          .status-bar {
            .message {
              border-top: 0;
              &:first-child {
                border-radius: 0;
              }
            }
          }
        }
        .search-box-field {
          float: left;
          width: 400px;
          padding: 5px;
        }
        .search-box-submit {
          margin-left: 2px;
          background-color: $color-off-black;
          padding: 6px;
          height: 30px;
          width: 30px;
          text-align: center;
          cursor: pointer;
          background: $black url('/media/export/images/click_collect/map_search.jpg') no-repeat
            center center;
          margin: 0;
          span {
            &:before {
              display: block;
              font-family: 'arial';
              speak: none;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              font-size: 16px;
              color: #000;
              text-decoration: none;
            }
          }
          .loading {
            span {
              &:before {
                content: '';
              }
            }
          }
        }
      }
      .location_more_info {
        display: none;
      }
      .search-box-cancel {
        background-color: blue;
        padding: 6px;
        height: 30px;
        width: 30px;
        text-align: center;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        z-index: 1;
        background: $black url('/media/export/images/click_collect/map_close.jpg') no-repeat center
          center;
        .loading {
          span {
            &:before {
              content: '';
            }
          }
        }
      }
      .search-box-geolocate {
        .loading {
          span {
            &:before {
              content: '';
            }
          }
        }
        background: $black url('/media/export/images/click_collect/map_geolocate.jpg') no-repeat
          center center;
        position: absolute;
        width: 49px;
        height: 50px;
        right: 0.5em;
        bottom: 5%;
        z-index: 1;
        overflow: hidden;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        color: $black;
        text-align: center;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        cursor: pointer;
        padding: 10px;
      }
      .address-map-directions-panel,
      .address-map-location-panel {
        top: 60px;
        bottom: 0;
        display: block;
        position: absolute;
        left: -275px;
        width: 300px;
        padding-right: 24px;
        z-index: 2;
        -webkit-transition: left 0.5s;
        transition: left 0.5s;
        header {
          top: 0;
          background: $white;
          height: 46px;
          z-index: 2;
          padding: 0;
          width: 276px;
          h2 {
            color: $black;
            font-size: 1.7em;
            padding: 10px;
            margin: 0;
            text-transform: uppercase;
            letter-spacing: -1px;
          }
        }
      }
      .address-map-directions-panel {
        left: 0px;
        a.close {
          background: $black url('/media/export/images/click_collect/map_close.jpg') no-repeat -5px 2px;
          height: 45px;
          display: block;
          width: 31px;
          position: absolute;
          right: 9px;
          top: 0;
          text-decoration: none;
          z-index: 3;
          margin: 0;
          text-indent: 0;
          color: $color-off-black;
          text-shadow: none;
          padding: 0;
          border-radius: 0;
          min-width: auto;
        }
      }
      .address-map-location-panel {
        a.close {
          background: $black url('/media/export/images/click_collect/map_panel_close.jpg') no-repeat
            4px -4px;
          height: 45px;
          display: block;
          width: 30px;
          position: absolute;
          right: -5px;
          top: 0;
          text-decoration: none;
          z-index: 1;
        }
        &.hover {
          left: -280px;
        }
        &.active {
          left: 0;
          a.close {
            background: $black url('/media/export/images/click_collect/map_close.jpg') no-repeat -5px
              2px;
            right: 11px;
          }
        }
      }
      .map {
        height: 100%;
        width: 100%;
      }
    }
    .search-box-geolocate,
    .search-box-cancel {
      span {
        &:before {
          display: block;
          font-family: 'arial';
          speak: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 16px;
          color: #000;
          text-decoration: none;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .select-address {
    div.ship_group_1 {
      display: block;
      text-transform: none !important;
      margin-bottom: 0px !important;
      margin-right: 5px;
    }
    div.ship_group_0 {
      display: block;
      text-transform: none !important;
      margin-bottom: 0px !important;
      margin-right: 5px;
    }
  }
  .map-container {
    .address-map-search-panel {
      .search-box {
        margin-top: 15px;
      }
    }
  }
  .click_and_collect_map {
    .overlay-content {
      .map-container {
        .address-map-search-panel {
          margin-left: 0;
          border-radius: 0;
          .search-box-field {
            width: 85%;
            font-size: 12px;
            border-radius: 0px !important;
            -webkit-appearance: none;
          }
        }
        .address-map-directions-panel {
          top: 0;
          max-height: 100%;
          overflow-y: scroll;
        }
        .address-map-location-panel.hover {
          left: 0px;
        }
        .address-map-location-panel {
          a.close {
            top: 10px;
          }
        }
      }
    }
    .overlay-container {
      background-color: #ffffff;
      border: 1px solid #cccccc;
      overflow-y: auto;
      overflow-x: auto;
      padding: 5px;
      position: fixed;
      z-index: 201;
      .close-container {
        margin: 5px 0;
        float: right;
      }
      a.close-link {
        background: transparent url(/images/css/lb-close.gif) no-repeat scroll 0 2px;
        color: #000000;
        padding-left: 14px;
      }
    }
    .overlay-background {
      background-color: black;
      filter: alpha(opacity=50);
      left: 0;
      opacity: 0.5;
      position: absolute;
      top: 0;
      z-index: 200;
    }
    select.overlay-hidden {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .map-container {
    .directions {
      .button {
        font-size: 10px;
        left: 80px;
        top: 3px;
      }
    }
    .address-map-directions-panel {
      .close {
        a.button {
          right: -10px;
        }
      }
    }
    .address-map-search-panel {
      .search-box-field {
        width: 30%;
      }
    }
    .map-info-window {
      .location {
        width: auto;
        padding: 0;
      }
    }
    .location {
      .title {
        span {
          line-height: 17px;
        }
      }
    }
    .address-map-location-panel {
      .location {
        .title {
          .distance {
            display: block;
            .uom {
              color: #888888;
            }
          }
        }
        .distance {
          display: none;
        }
      }
    }
  }
  .click-and-collect {
    .local-collection {
      float: left;
      width: 100%;
      .show-collection-point {
        h3 {
          margin-bottom: 0.5em;
        }
        a {
          &.local-collection-link {
            display: inline;
          }
        }
      }
    }
  }
  .click_and_collect_map {
    .overlay-content {
      .map-container {
        .map-info-window {
          max-width: 185px;
          .location {
            .distance {
              p {
                line-height: 17px !important;
              }
            }
            .address {
              p {
                line-height: 17px !important;
              }
            }
            .title {
              padding-top: 2px;
            }
            .location_description {
              display: none;
            }
            .opening_hours {
              display: none;
            }
            .image {
              display: none;
            }
            .location_more_info {
              color: white !important;
              text-decoration: underline;
              cursor: pointer;
              line-height: 2em !important;
              display: block;
            }
          }
        }
        .address-map-search-panel {
          .status-bar-wrapper {
            position: fixed;
            bottom: 50px;
            width: 100%;
            margin: 0;
            left: 0;
            text-align: center;
          }
          .search-box-field {
            width: 14.3em;
          }
        }
        .address-map-directions-panel {
          width: 100%;
          top: 0;
          max-height: 100%;
          overflow-y: scroll;
          a.close {
            right: 35px;
          }
          header {
            width: 100%;
            .button {
              a {
                margin-right: 20px;
              }
            }
          }
          left: 0px;
        }
        .address-map-location-panel {
          width: 100%;
          top: auto;
          left: 0pc;
          bottom: 0px;
          width: 100%;
          height: 30px;
          margin: 0;
          padding-bottom: 0;
          overflow: hidden;
          a.close {
            right: 24px;
            top: 10px;
            background: $black url('/media/images/checkout/map_mobile_panel_close.gif') no-repeat
              0px 0px;
            width: 30px;
            height: 30px;
            span {
              &:before {
                content: '\f0d8';
              }
            }
          }
          header {
            width: 100%;
            padding-top: 5px;
          }
          .location {
            &:first-child {
              padding-top: 0px;
            }
          }
        }
        .address-map-location-panel.active {
          a.close {
            top: 10px;
            background: $black url('/media/export/images/click_collect/map_close.jpg') no-repeat -4px -5px;
            width: 30px;
            height: 30px;
          }
          top: 0px;
          height: auto;
        }
        .search-box-geolocate {
          bottom: 20%;
        }
      }
      .search-box-cancel {
        span {
          &:before {
            padding-top: 0;
          }
        }
      }
    }
  }
  .address-map-location-panel {
    header {
      h2 {
        margin-top: -12px;
        font-size: 19px !important;
      }
    }
    a {
      span {
        display: none;
      }
    }
  }
  .search-box-wrapper {
    .search-box {
      .search-box-submit {
        height: 200px;
      }
    }
  }
  .click_and_collect_map {
    .overlay-content {
      .map-container {
        .address-map-location-panel {
          header {
            h2 {
              padding: 0px;
            }
          }
          a.close {
            top: 0px !important;
          }
        }
        .address-map-directions-panel {
          a.close {
            border: 0px;
            font-size: 30px;
            background: url('/media/export/images/click_collect/map_close.jpg') no-repeat -2px 2px;
            height: 42px;
            display: block;
            width: 42px;
            position: absolute;
            right: -5px;
            top: -2px;
            text-decoration: none;
            z-index: 3;
            margin: 0;
            text-indent: 0;
            color: $color-off-black !important;
            padding: 0px;
            font-size: 0px;
            border-radius: 0px;
          }
          background: #fff;
          div.close {
            position: relative;
          }
        }
      }
    }
  }
  .address-map-location-panel {
    .locations {
      .button {
        background: none;
        border: 0px none;
        padding: 0px;
        text-align: left;
        margin: 0px;
        width: 97%;
        float: left;
        a {
          text-align: center;
          text-decoration: none;
        }
        a.select-access-point {
          margin-bottom: 10px;
        }
      }
    }
    .button {
      a {
        border-radius: 4px;
        padding: 0.3em 0.8em !important;
        background: $color-off-black !important;
        display: block;
        text-align: center;
        width: 90%;
      }
    }
  }
  .map-info-window {
    .button {
      background: none;
      border: 0px none;
      padding: 0px;
      text-align: left;
      margin: 0px;
      width: 100%;
      a {
        display: block;
        text-align: center;
        width: 90%;
        font-family: inherit;
        font-size: inherit;
        font-weight: bold;
        text-transform: uppercase;
        padding: 0.2em 0.8em;
        background: $color-off-black;
        color: #fff;
        border: none;
        white-space: nowrap;
        cursor: pointer;
        text-decoration: none;
      }
      a.select-access-point {
        margin-bottom: 10px;
      }
    }
  }
  .more-info-icon-popup {
    display: inline-block;
    padding-top: 0px;
    position: relative;
    top: 3px;
    left: 2px;
  }
  .left {
    .more-info-icon-popup {
      left: 15px;
    }
  }
  .shipmethod-delivery {
    label {
      float: left;
      display: inline-block !important;
      width: 92% !important;
    }
    input {
      float: left;
      display: inline-block !important;
    }
  }
  .set-collection-point {
    .local-collection-button,
    .store-collection-button {
      background: #000;
      color: #ffffff;
      display: block;
      font-size: 10px;
      font-weight: bold;
      height: 30px;
      line-height: 22px;
      padding: 0.4em 1em 0.3em;
      text-align: center;
      text-transform: uppercase;
      width: 170px;
    }
  }
  .mobile {
    .utility-nav.detached {
      position: relative !important;
      z-index: 0 !important;
    }
  }
  .adp-placemark {
    td {
      padding: 2px 0px 2px 20px;
    }
  }
  .directions {
    table {
      width: 100%;
    }
    .adp-text {
      width: 90%;
    }
  }
}

@media (max-width: 320px) {
  .map-container {
    .directions {
      .button {
        left: 70px;
      }
    }
  }
}
