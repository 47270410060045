.welcome-15 {
  .site-email-signup {
    &__success-terms {
      margin: 37px 0 0 1px;
      text-align: left;
      width: 185px;
      height: 20px;
    }
  }
}

.store-locator__controls {
  select {
    text-transform: capitalize;
  }
}

#cboxContent {
  .welcome-15 {
    .site-email-signup__success-terms {
      text-align: left;
    }
  }
}

.site-header {
  .signin-widget {
    float: $rdirection;
    &__header {
      margin-top: 12px;
    }
  }
}

.salon-takeover {
  .site-header {
    &__wrapper {
      .block-template-country-picker-eu-v1 {
        @include breakpoint($landscape-up) {
          right: 15%;
        }
        @include breakpoint($portrait-only) {
          right: 20%;
        }
      }
    }
  }
}

.section-chatprivacypolicy,
.page-node-75604,
.page-node-75606,
.page-node-75608,
.page-node-75610,
.page-node-75612,
.page-node-75614 {
  .site-header {
    &__wrapper {
      display: none;
    }
  }
  .site-footer,
  .offer-block {
    display: none;
  }
  // !important was used because the live chat styles are controlled by LivePerson as inline.
  .LPMcontainer {
    display: none !important;
  }
  .site-content {
    padding-top: 10px !important;
  }
}

.device-pc {
  .gnav-country {
    &-language-chooser {
      .menu {
        max-height: 375px;
      }
    }
  }
}

.device-mobile {
  &.salon-takeover {
    .site-header {
      &__utility-logo {
        float: $rdirection;
        display: inline-block;
        .icon--logo {
          &--mobile {
            margin-#{$rdirection}: 40px;
          }
        }
        &.salon-takeover {
          &__logo {
            float: none;
          }
        }
      }
    }
  }
}

@include breakpoint($xlarge-up) {
  .getthefacts-container {
    .getthefacts {
      &__store_meta_info {
        width: 100%;
        &--store_hours,
        &_layer {
          width: 50%;
          float: $ldirection;
        }
      }
    }
  }
  .payment_block {
    float: $rdirection;
    width: 50%;
  }
}

#profile_preferences {
  .profile-page {
    &__content {
      margin-bottom: 20px;
    }
  }
}

.offer-block-formatter {
  & &__content--alt {
    justify-content: center;
  }
}

.domain-eu {
  &.device-mobile {
    .rounded-live-chat-button {
      bottom: 72px;
    }
  }
}

#viewcart,
#signin,
#samples,
#shipping_pl,
#review,
#payment {
  .rounded-live-chat-button {
    bottom: 80px;
  }
}

.block-template-countdown-timer-v1 {
  .offers-banner-formatter {
    height: 60px;
  }
  .countdown-timer {
    height: 60px;
    &__inner {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      color: inherit;
    }
  }
}

.viewcart {
  .payments-icon {
    .logo {
      &_visa {
        background: url('/media/export/images/checkout/payment_logo/visa.gif') no-repeat;
        background-size: 100%;
      }
      &_maestro {
        background: url('/media/export/images/checkout/payment_logo/cc_maestro.gif') no-repeat;
        background-size: 100%;
      }
      &_mc {
        background: url('/media/export/images/checkout/payment_logo/mc.gif') no-repeat;
        background-size: 100%;
      }
      &_paypal {
        background: url('/media/export/images/checkout/payment_logo/paypal.gif') no-repeat;
        background-size: 100%;
      }
      &_sofort {
        background: url('/media/export/images/checkout/payment_logo/sofort.png') no-repeat;
        background-size: 100%;
      }
    }
  }
}
