#power_review_container {
  float: left;
  width: 97%;
  .product-full {
    &__container {
      background: $white;
      padding: 40px 0 20px;
      position: relative;
    }
    &__image {
      width: 25%;
      float: left;
      @include breakpoint($small-down) {
        width: 100%;
      }
    }
    &__details {
      float: left;
      width: 75%;
      @include breakpoint($small-down) {
        width: 100%;
      }
      .product-name {
        position: absolute;
        left: 0;
        width: 24%;
        line-height: 28px;
        color: $color-off-black;
        font-family: $primary-font;
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 15px;
        text-transform: uppercase;
        font-weight: bold;
        @include breakpoint($small-down) {
          top: 67px;
          width: auto;
          text-align: center;
        }
      }
    }
    &__image {
      img {
        margin-right: 10px;
        margin-bottom: 10px;
        float: left;
        @include breakpoint($small-down) {
          width: 100%;
        }
      }
    }
  }
  .p-w-r {
    .thank-you-page {
      .col-sm-12 {
        .pr-rating-number {
          border: none;
          span {
            float: left;
          }
        }
        span {
          float: none;
          display: inline;
          font-weight: normal;
          padding-left: 5px;
        }
      }
      .headline {
        font-family: $primary-font;
        font-weight: normal;
      }
    }
    .pr-media-preview {
      .pr-loading-indicator {
        @include breakpoint(($small-down) (orientation portrait)) {
          width: 35px;
          height: 35px;
        }
      }
    }
    .pr-btn-danger {
      min-width: auto;
      color: $white;
      background-color: $color-off-black;
      border-color: $color-off-black;
      @include breakpoint(($small-down) (orientation portrait)) {
        padding: 8px 10px;
      }
    }
    fieldset {
      width: auto;
    }
    .pr-header {
      border-bottom: none;
      @include breakpoint($small-down) {
        position: absolute;
        top: 10px;
        width: 100%;
      }
    }
    .pr-header-title {
      margin: 0;
    }
    span {
      font-size: 16px;
      line-height: 1.5;
      font-weight: bold;
      width: auto;
    }
    .pr-label-control.checked {
      span {
        float: none;
        color: $white;
      }
    }
    .input-add-tag {
      .pr-btn-add-tag-submit {
        margin-left: 15px;
        color: $color-off-black;
        border-left: 1px solid #adadad;
        @include breakpoint($small-down) {
          margin: 10px 0 0 0;
        }
      }
    }
    label span::first-letter {
      text-transform: capitalize;
    }
    input {
      width: 85% !important;
      font-family: $primary-font;
      color: $color-gray;
      font-size: 16px;
      background: $white;
      @include breakpoint($small-down) {
        width: 100% !important;
      }
    }
    input::placeholder {
      padding-left: 15px;
      font-size: 16px !important;
    }
    select {
      max-width: 85%;
      height: auto;
      font-family: $primary-font;
      font-size: 16px;
      text-transform: uppercase;
      padding-left: 20px !important;
      color: $color-gray;
      background: $white;
      @include breakpoint($small-down) {
        max-width: 100%;
      }
    }
    textarea {
      font-family: $primary-font;
      width: 85% !important;
      font-size: 16px;
      color: $color-gray;
      background: $white;
      @include breakpoint($small-down) {
        width: 100% !important;
      }
    }
    textarea::placeholder {
      padding-left: 15px;
      font-size: 16px;
    }
    .pr-war {
      max-width: 1024px;
    }
    .pr-header-title {
      color: $color-off-black;
      font-family: $primary-font;
      font-size: 24px;
      line-height: normal;
      display: block;
      font-weight: bold;
      padding-bottom: 15px;
    }
    #pr-war-form {
      .form-group {
        input::placeholder {
          font-size: 11px;
          line-height: 1.5;
        }
        #pr-bottomline {
          .btn-group-2-count {
            width: 85%;
            .pr-btn.pr-btn-default {
              font-size: 16px;
              color: $color-off-black;
              padding-left: 25px;
              background: $white;
            }
            .pr-btn.pr-btn-default.active {
              background: $color-off-black;
              color: $white;
            }
            @include breakpoint($small-down) {
              width: 100%;
            }
          }
          .pr-clear-all-radios {
            color: $color-off-black;
            font-size: 16px;
          }
        }
        &.pr-media_image-form-group,
        &.pr-media_videourl-form-group {
          width: 85%;
          @include breakpoint($small-down) {
            width: 100%;
          }
          label {
            color: $white;
            background-color: $color-off-black;
            border-color: $color-off-black;
            padding: 6px;
            width: 195px !important;
            @include breakpoint($small-down) {
              width: 180px !important;
            }
            &:hover {
              background-color: $color-off-black;
              border-color: $color-off-black;
            }
            span {
              color: $white;
              &:after {
                color: $white;
              }
            }
          }
        }
      }
      .form-group {
        &.pr-has-error {
          input {
            &.form-control {
              border-color: $color-red;
            }
          }
          label {
            &.pr-control-label span {
              color: $color-red;
            }
          }
        }
      }
      .form-group {
        &.pr-rating-form-group {
          label {
            color: $color-off-black;
            span {
              color: $color-off-black;
            }
          }
        }
      }
      .form-group {
        .tag-group {
          .pr-label-control {
            &.checked {
              background-color: $color-off-black;
              white-space: initial;
            }
          }
        }
      }
    }
    .pr-clear-all-radios {
      color: $color-off-black;
    }
    .pr-submit {
      .pr-footer {
        .pr-subscript {
          font-size: 16px;
          line-height: 1.2;
          a {
            font-size: 16px;
          }
        }
      }
    }
  }
}
