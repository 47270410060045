#profile_preferences {
  &.change-password {
    .profile__change-password-overlay {
      .profile-info {
        &__item:first-child {
          label {
            float: none;
          }
        }
      }
    }
  }
}

.appt-book-section {
  .error-messages {
    color: $color-red;
  }
}

.change-password {
  .password-field {
    &__info {
      .appt-book & {
        #{$ldirection}: 65%;
        width: 25%;
      }
    }
  }
}
