#checkout {
  padding-top: 40px;
  #checkout-terms {
    margin-top: 10px;
  }
  #review-address-info {
    margin-top: 20px;
  }
}

.checkout {
  .sign-in-page {
    padding-top: 50px;
    h3.checkout-panel__heading {
      font-size: 28px;
      line-height: 30px;
      margin-bottom: 8px;
      letter-spacing: -1px;
      font-weight: normal;
      margin: 15px 0;
      min-height: 60px;
    }
    .new-account,
    .return-user {
      width: 48%;
      margin: 0 0;
      float: left;
      margin-right: 15px;
      position: relative;
      min-height: 300px;
    }
    .return-user {
      margin-right: 0px;
    }
    input[type='submit'] {
      position: absolute;
      top: 243px;
      height: 40px;
      background: none;
      color: $color-off-black;
      border: 1px solid $color-off-black;
      &:hover {
        background: $color-off-black;
        color: $white;
      }
    }
  }
  .checkout__content {
    .checkout-panel__header {
      .checkout-panel__heading {
        font-size: 28px;
        line-height: 30px;
        letter-spacing: -1px;
        font-weight: normal;
        text-transform: uppercase;
        margin: 15px 0;
        margin-bottom: 0;
      }
    }
  }
  #delivery-options {
    .ship-method-group,
    .field-note {
      margin: 10px 0;
      font-weight: normal;
      a.text_link {
        text-transform: uppercase;
        font-weight: normal;
        border-bottom: 1px solid $color-off-black;
        text-decoration: none;
        display: inline-block;
        margin-bottom: 10px;
      }
    }
  }
  .right_align {
    float: right;
  }
  .address_controls {
    li {
      margin: 10px 0;
      a {
        border-bottom: 1px solid $color-off-black;
        text-decoration: none;
        display: inline-block;
        margin-bottom: 10px;
        &.add-address {
          margin-left: 15px;
        }
      }
    }
  }
  .address_form_container {
    .form_element {
      width: 46.5%;
      float: left;
      margin-right: 19px;
      input {
        width: 100%;
        background: $white;
      }
      &.title_container {
        width: 100%;
        margin-bottom: 10px;
        input[type='radio'] {
          width: auto;
          @include title_radio;
          float: left;
        }
        label {
          @include title_label;
          margin: 0 20px 0 10px;
          &:first-child {
            width: 100%;
            margin: 10px 0 5px 0px;
          }
          &.error {
            span.label-content {
              color: #ff0000 !important;
            }
          }
        }
      }
    }
    #form--shipping_-_-address--field--SHIPFORM_TITLE {
      &--index--ms_label,
      &--index--ms,
      &--index--dr_label,
      &--index--dr {
        display: none;
      }
    }
  }
  #bill_to_shipping_container {
    margin: 20px 0;
    input[type='radio'] ~ label {
      margin-left: 10px;
    }
  }
  .checkout__content {
    fieldset.fs {
      max-width: 46em;
    }
  }
  .checkout-panel--order-summary {
    border-bottom: 1px solid $light-gray;
    .label {
      width: 75%;
    }
    .value {
      width: 25%;
    }
  }
  .offer_code_module {
    .left {
      padding: 0;
      float: left;
      margin-bottom: 20px;
      width: 61%;
      & ~ .left {
        padding: 0;
        width: 28%;
        position: relative;
        bottom: 1px;
        right: 1px;
        float: left;
      }
    }
    p {
      clear: both;
    }
  }
  #additional_info {
    margin-bottom: 10px;
    a {
      text-transform: uppercase;
      border-bottom: 1px solid $color-off-black;
      text-decoration: none;
      display: inline-block;
      margin-bottom: 10px;
    }
  }
  a.checkout-buttons-content__item.disabled.button.continue-checkout {
    background-color: $color-gray;
    border-color: $color-gray;
    cursor: default;
  }
  .promotions-container {
    .newsletter-info__header {
      display: none;
    }
    #providing-email {
      margin-top: 15px;
    }
  }
  #billing_address_form_container {
    #form--billing_-_-address--field--BILLFORM_TITLE {
      &--index--ms_label,
      &--index--ms,
      &--index--dr_label,
      &--index--dr {
        display: none;
      }
    }
  }
  .privacy_policy_container {
    margin-top: 10px;
  }
}

.offers-overlay {
  .offer-block-formatter {
    &__header-text {
      p {
        margin: 0;
        padding-top: 20px;
        font-size: 20px;
        font-weight: 300;
        line-height: normal;
        @include breakpoint($landscape-up) {
          font-size: 38px;
        }
      }
    }
    &__content--alt {
      justify-content: center;
    }
  }
}

#review,
#payment {
  #review-instructions {
    margin-top: 25px;
  }
  #review-address-info {
    .checkout-panel__heading {
      text-transform: uppercase;
      font-size: 14px;
      color: $gray;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0 0 5px 0;
    }
    .trans_detail_item {
      width: 200px;
      margin-bottom: 60px;
      margin-right: 24px;
      position: relative;
      float: left;
      word-wrap: break-word;
      &.delivery_method_container {
        margin-right: 0px;
      }
      &:nth-child(3) {
        margin-right: 0px !important;
      }
      a.text_link {
        margin-left: 20px;
        text-decoration: underline;
      }
    }
  }
  #cart-items {
    thead {
      border-bottom: 1px solid $light-gray;
    }
    tr.cart-item-row-border {
      border-bottom: 1px solid $light-gray;
    }
    tr.subtotal-row {
      td.subtotal {
        text-align: right;
        text-transform: none;
        padding-bottom: 60px;
        padding-top: 10px;
        .total_value {
          float: right;
          margin-left: 20px;
        }
      }
    }
    th {
      color: $gray;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      padding-bottom: 9px;
      &.product {
        width: 25%;
      }
      &.prod-info {
        width: 25%;
      }
      &.prod-price {
        width: 18%;
      }
      &.prod-qty {
        width: 13%;
      }
      &.total {
        width: 19%;
        padding-left: 52px;
      }
    }
    td.thumb {
      width: 15%;
      padding-top: 30px;
    }
    td.name {
      padding-right: 2%;
      width: 35%;
      padding-top: 30px;
      padding-bottom: 30px;
      position: relative;
      height: 152px;
      a {
        font-weight: bold;
        line-height: normal;
        text-transform: uppercase;
        border-bottom: none;
        &.remove_link {
          text-decoration: underline;
          font-weight: normal;
          letter-spacing: 0.05em;
          bottom: 20px;
          position: absolute;
        }
      }
    }
    td.price {
      width: 18%;
      padding-left: 0;
      padding-top: 30px;
    }
    td.qty {
      width: 13%;
      padding-left: 0;
      padding-top: 30px;
      .select-box__label {
        min-width: 77px;
      }
    }
    td.total {
      padding-left: 52px;
      padding-top: 30px;
      width: 19%;
    }
    &.checkout-table {
      td {
        &.name {
          padding-#{$rdirection}: 2%;
          width: 32%;
        }
        &.total {
          padding-#{$ldirection}: 0;
        }
      }
    }
  }
  .checkout-buttons-container {
    .checkout-buttons {
      margin-top: 20px;
      float: right;
      z-index: 1;
    }
  }
}

ul.error_messages.error {
  margin: 10px 0 15px;
  color: $red;
}

.payment-properties {
  h2.checkout-panel__heading {
    font-size: 14px;
    color: $gray;
    font-weight: bold;
  }
  #payment-type-cc {
    input[type='radio'] {
      float: left;
      margin: 2px 0;
      width: auto;
    }
    label {
      float: left;
      clear: none;
      display: inline-block;
      margin: 0 10px;
      width: auto;
    }
  }
  #payment-other-terms {
    margin-top: 15px;
  }
}

#confirm {
  h2 {
    margin-top: 25px;
  }
  p.instructions:nth-child(2) {
    font-size: 19px;
    .bold {
      font-weight: bold;
    }
  }
  #print-order {
    .left {
      float: left;
      a {
        background-color: $color-off-black;
        color: $white;
        width: auto;
        &:hover {
          background-color: $white;
          color: $color-off-black;
          width: auto;
        }
      }
    }
    .right {
      float: right;
    }
  }
  .privacy_policy_container {
    input[type='checkbox'] ~ label {
      width: 90%;
    }
  }
  .fs.create-account {
    .form_element.pc_email_container {
      margin: 0 0 15px 0;
    }
  }
  .form-item {
    height: auto;
    &.email-address {
      label {
        float: left;
        width: 100%;
      }
      input ~ span {
        font-size: 19px;
      }
    }
    &.password {
      input {
        width: 27%;
      }
    }
  }
  .checkout-panel {
    &__salon--header {
      margin-top: 20px;
    }
  }
}

#colorbox {
  .checkout {
    margin-top: 0px !important;
  }
  .address_form_container {
    width: 710px;
    .address-overlay-shipping {
      h2 {
        font-size: 26px;
        text-align: left;
        position: static;
        border-bottom: 1px solid $light-gray;
        font-weight: normal;
        margin-bottom: 10px;
        letter-spacing: -2px;
        bottom: 11px;
        padding: 0 0 12px;
        text-transform: uppercase;
      }
    }
    .form_element {
      input[type='checkbox'] {
        width: auto;
      }
    }
  }
}

.shipform_fields {
  .ship-method-group-label {
    input[type='radio'] {
      margin-right: 10px;
    }
    label {
      margin-right: 20px;
      a {
        margin-left: 10px;
        font-style: italic;
        font-size: 12px;
      }
    }
  }
}

#payment {
  .checkout__content {
    p {
      margin: 10px 0 10px 0;
    }
  }
}

.esalon__description {
  margin-bottom: 7px;
}

.esalon__form-item--checkbox {
  margin-top: 20px;
  input[type='checkbox'] ~ label {
    clear: none;
    display: inline-block;
    margin-left: 10px;
    width: auto;
  }
}

.esalon__field-container {
  .form-item {
    width: 100%;
    .select-box {
      width: 100%;
      margin: 10px 0 0 0;
      &.error .select-box__label {
        border: 1px solid $color-red;
      }
    }
    .select-box__label {
      height: 35px;
      line-height: 35px;
    }
  }
  a.esalon__salon-finder-link {
    margin: 10px 0 10px 0;
    display: inline-block;
    text-decoration: underline;
  }
  span.label {
    display: block;
    margin: 0 0 20px 0;
  }
}

.salon-search-locator {
  .salon-search-locator__city {
    label {
      float: left;
      width: 100%;
    }
  }
}

@media (min-width: 1024px) {
  .salon-search-locator {
    &.fs {
      .form-item {
        input,
        select {
          width: 60%;
        }
      }
    }
  }
}

.viewcart {
  .checkout-panel {
    &--viewcart {
      .cart-items {
        &__item {
          &--desc {
            line-height: 21px;
            width: 33%;
            word-wrap: break-word;
            @include breakpoint($landscape-up) {
              padding-bottom: 40px;
            }
          }
        }
      }
    }
  }
}
