.hair-quiz-page {
  .quiz-landing-page-formatter.show-quiz {
    .mantle-form-options-field {
      &__footer-cta {
        a {
          font-size: 16px;
          padding-top: 12px;
          width: 280px;
        }
      }
    }
  }
}

.site-header {
  &__menu {
    .menu {
      &__item {
        &--lvl-2,
        &--lvl-3 {
          white-space: nowrap;
        }
      }
    }
  }
}

.product-full {
  &__actions-fave {
    margin-bottom: 15px;
  }
}
