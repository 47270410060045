.new-account__fieldset {
  .form_element.pp_birth_day_container {
    .birthday_month,
    .birthday_date,
    .birthday_year {
      margin-right: 5px;
    }
  }
}

.password-reset-page,
.password-request-page,
.password-sent-page {
  padding: 300px 30px 10px 40px;
}

@mixin title_radio {
  border: 0;
  display: inline;
  width: auto;
}

@mixin title_label {
  margin-right: 3px;
  cursor: pointer;
  float: left;
}

.title_radio {
  @include title_radio;
}

.title_label {
  @include title_label;
}

#profile_preferences {
  .profile-page {
    &__content {
      margin-bottom: 20px;
    }
  }
  .profile-info {
    &__item:first-child {
      width: 100%;
      margin-bottom: 20px;
      input[type='radio'] {
        @include title_radio;
      }
      label {
        @include title_label;
        &.label {
          width: 100%;
        }
        &.error {
          span.label-content {
            color: #ff0000 !important;
          }
        }
      }
    }
  }
  h3.newsletter-info__header {
    margin: 10px 0;
  }
  p.spacer {
    margin: 0 0 20px 0;
  }
  p.instructions {
    margin: 10px 0;
  }
  .privacy-policy {
    padding-top: 5px;
    legend {
      font-size: 28px;
      font-weight: normal;
      letter-spacing: -1px;
      line-height: 30px;
      margin-bottom: 5px;
      text-transform: uppercase;
    }
    input[type='checkbox'] ~ label,
    input[type='checkbox'] ~ .label {
      width: 94%;
      margin-left: 1px;
    }
    .privacy-policy__required {
      vertical-align: top;
      margin: 0 0 0 5px;
    }
  }
}

.address-book {
  .column {
    float: left;
    margin-right: 2.5%;
    width: 39%;
    padding: 16px 23px;
    background: $light-gray;
    margin-bottom: 20px;
    min-height: 300px;
    position: relative;
    .address_display {
      .controls {
        margin: 10px 0;
        text-transform: uppercase;
        position: absolute;
        bottom: 0px;
        a {
          margin-right: 10px;
          text-decoration: underline;
          &.js-default-address {
            float: left;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  h3.other_address_header {
    font-weight: bold;
    margin-right: 2.5%;
    width: 39%;
    background: none;
    margin-bottom: 15px;
  }
}

.address-book-page__overlay {
  .address-form {
    .form-item.first-name {
      margin-bottom: 10px;
      input[type='radio'] {
        @include title_radio;
      }
      label {
        @include title_label;
      }
    }
  }
}

.past-purchases {
  &__content {
    p#reorder-btn {
      margin: 0 0 50px 0;
    }
  }
  &__created-date {
    width: 15%;
  }
  &__trans-id {
    width: 25%;
  }
  &__carrier,
  &__order-status,
  &__tracking {
    width: 20%;
  }
  &__carrier,
  &__tracking {
    float: $ldirection;
    height: auto;
    min-height: 20px;
    word-break: break-word;
  }
  &__shopping {
    a {
      text-decoration: none;
    }
  }
}

.address-form,
#shipping .fs.address {
  .form_element.country_container {
    margin-bottom: 1.5em;
    .js-country-display {
      font-style: italic;
    }
  }
  .phone_label_container {
    margin-bottom: 1em;
  }
}

.address-overlay-shipping {
  input[type='submit'] {
    margin: 20px 0;
  }
}

#address_book {
  .address-form {
    .form_element {
      width: 46.5%;
      float: left;
      margin-right: 19px;
      input {
        width: 100%;
        background: $white;
      }
      &.title_container {
        width: 100%;
        margin-bottom: 10px;
        input[type='radio'] {
          width: auto;
          @include title_radio;
          float: left;
        }
        label {
          @include title_label;
          margin: 0 20px 0 10px;
          &:first-child {
            width: 100%;
            margin: 10px 0 5px 0px;
          }
          &.error {
            span.label-content {
              color: #ff0000 !important;
            }
          }
        }
      }
    }
  }
}

.en_FR {
  .address-form,
  .fs.address {
    .form_element.city_container {
      clear: both;
      margin: 15px 0 15px -15px;
      .city-results {
        p {
          margin: 35px 0 20px 19px;
        }
        select {
          margin: 15px 0 20px 15px;
        }
      }
      input[type='text'] + label {
        margin-top: -26px;
        height: 0px;
      }
      input[type='text'] + label.city_label_txt {
        height: 10px;
      }
      input[type='text'].adpl__mode-label + label:before,
      select.adpl__mode-label + label:before {
        margin-top: 41px;
      }
    }
  }
}

.en_ES {
  .address-form,
  .fs.address {
    .form_element.state_container {
      height: 5em;
      clear: both;
      width: 100% !important;
      margin-bottom: 15px;
      label {
        display: block;
      }
    }
  }
}

.order-details-page__content {
  .order-products {
    .cart-item {
      &__products {
        width: 50%;
      }
      &__thumb {
        width: 20% !important;
      }
      &__price {
        width: 15%;
      }
      &__qty {
        width: 15%;
      }
    }
  }
  .order-total {
    width: 54%;
  }
}

.sign-in-page {
  input[type='checkbox'] ~ label.error,
  input[type='checkbox'] ~ .label.error {
    color: #ff0000 !important;
  }
  input[type='checkbox'].error {
    outline: 1px solid #ff0000 !important;
  }
}

.order-details-page {
  &__content {
    .order-products {
      .cart-item__product-subname {
        display: block;
      }
    }
    .order-details__item.order-details__payment {
      padding-bottom: 15px;
    }
  }
}

.site-header {
  .gnav-util--cart {
    .gnav-util {
      &__content--cart {
        margin-top: 75px;
      }
    }
  }
}
